import translations from './translations'

translations.add('el', {
    title: 'Διαχείριση ομάδας',
    inviteTeammate: 'Προσθήκη χρήστη',
    editTeammate: 'Επεξεργασία χρήστη',
    cancel: 'Ακύρωση',
    submit: 'Ολοκλήρωση',
    invite: 'Καλέστε',
    formFields: {
        email: 'Ηλεκτρονική διεύθυνση',
        store: 'Κατάστημα',
        firstName: 'Ονομα',
        lastName: 'Επίθετο',
        teammatePermissions: 'Πρόσβαση χρήστη',
        inviteAsAdmin: 'Διαχειριστής',
        inviteAsRestrictedAccess: 'Περιορισμένη πρόσβαση',
        accountantAccess: 'Πρόσβαση στα οικονομικά',
        customAccess: 'Προσαρμοσμένη πρόσβαση',
        grantedPermissions: 'Ρυθμίσεις αδειών',
        accessLevels: 'επίπεδα πρόσβασης',
        sectionName: 'Όνομα ενότητας',
        noAccess: 'Καμία πρόσβαση',
        readAccess: 'Μόνο για ανάγνωση',
        fullAccess: 'Πλήρης πρόσβαση',
        sections: {
            overview: 'ΣΦΑΙΡΙΚΗ ΕΙΚΟΝΑ',
            online_payments: 'Διαδικτυακές πληρωμές',
            pos_payments: 'Πληρωμές POS',
            pos_amex_payments: 'POS American Express',
            payment_links: 'Σύνδεσμοι για πληρωμή',
            virtual_terminal: 'Εικονικό τερματικό',
            settlements: 'Διακανονισμοί',
            invoices: 'Τιμολόγια',
            reports: 'Αναφορές',
            payment_methods: 'Ρυθμίσεις. Διαδικτυακές Μέθοδοι Πληρωμής',
            teammates: 'Ρυθμίσεις. Διαχείριση Ομάδας',
            chargebacks: 'Αντιστροφές χρεώσεων',
            'merchant.profile': 'Ρυθμίσεις. Προφίλ εταιρείας',
            'merchant.ecom_stores': 'Ρυθμίσεις. Διαδικτυακά Καταστήματα',
            'merchant.pos_stores': 'Ρυθμίσεις. Σημεία Πώλησης (POS)',
            'merchant.bank_accounts': 'Ρυθμίσεις. Τραπεζικοί λογαριασμοί'
        }
    },
    formFieldsPlaceholders: {
        role: 'Select role'
    },
    formErrors: {
        emailRequired: 'Απαιτείται email',
        roleRequired: 'Role is required',
        shopRequired: 'Απαιτείται κατάστημα',
        enterValidEmail: 'Παρακαλώ εισάγετε έγκυρο email',
        firstNameRequired: 'Το όνομα είναι υποχρεωτικό',
        lastNameRequired: 'Last name is required'
    }
})

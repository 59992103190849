import React from 'react'
import {Spin} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'

import {FilterContainer, SelectWithTitleContainer} from '@/components'
import translations from '@/translations/translations'

import {IOutletListFilterContainerStore} from './IOutletListFilterContainerStore'
import {OutletListFilterContainerProps} from './props'
import styles from './OutletListFilterContainer.scss'

export const OutletListFilterContainer: React.FC<OutletListFilterContainerProps> = observer(
    ({injectableIdentifier}) => {
        const store = useInjection<IOutletListFilterContainerStore>(injectableIdentifier)

        return (
            <div className={styles.filterContent}>
                <Spin spinning={store.isLoading} indicator={null}>
                    <FilterContainer store={store} formId={'pos-outlets-popover-filter-form'} />
                </Spin>
                <Spin spinning={store.isLoading} indicator={null}>
                    <SelectWithTitleContainer
                        displayValue={translations().fields.status}
                        store={store.statusSelectStore}
                    />
                </Spin>
            </div>
        )
    }
)

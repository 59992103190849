import React, {PropsWithChildren} from 'react'
import {Divider, Spin, Typography} from 'antd'
import {observer} from 'mobx-react'

import {BackButton, Container, IconTitle, PageHeader} from '@/components'
import {getRouterStore} from '@/router/utils'

import {PageContainerProps} from './props'
import styles from './PageContainer.scss'

export const PageContainer: React.FC<PropsWithChildren<PageContainerProps>> = observer(
    ({children, title, isLoading, extra, onBack, tooltipText, hasDivider = true}) => {
        return (
            <Spin spinning={isLoading}>
                <PageHeader
                    onBack={null}
                    title={
                        <IconTitle
                            title={
                                <Typography.Title className={styles.title} level={4}>
                                    {title}
                                </Typography.Title>
                            }
                            tooltipText={tooltipText}
                        />
                    }
                    className={styles.header}
                    border={null}
                >
                    <div className={styles.headerContent}>
                        <BackButton
                            onClick={() => {
                                if (onBack) {
                                    onBack()
                                } else {
                                    getRouterStore().back()
                                }
                            }}
                        />
                        {extra}
                    </div>
                    {hasDivider && <Divider className={styles.divider} />}
                </PageHeader>

                <Container>{children}</Container>
            </Spin>
        )
    }
)

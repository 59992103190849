import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import classNames from 'classnames'
import {useInjection} from 'dna-react-ioc'
import {Descriptions, Empty, Typography} from 'antd'

import {useScreenType} from '@/hooks'
import {Card, Container, OutletStatus, PageContainer} from '@/components'

import {BankAccountCard} from '@/pages/BankAccounts/components'
import {DossierBankDetail} from '@/stores/bank-accounts'

import {IOutletDetailsContainerStore} from './IOutletDetailsContainerStore'
import translations from './translations'
import {OutletDetailsContainerProps} from './props'
import styles from './OutletDetailsContainer.scss'

export const OutletDetailsContainer: React.FC<OutletDetailsContainerProps> = observer(
    ({injectableIdentifier, id, containerTitle}) => {
        const {isLoading, outletDetails, loadDossierMerchantOutletDetails} =
            useInjection<IOutletDetailsContainerStore>(injectableIdentifier)
        const {isMobile} = useScreenType()

        useEffect(() => {
            loadDossierMerchantOutletDetails(id)
        }, [id])

        const renderBankAccount = (bankAccount: DossierBankDetail) => (
            <>
                <BankAccountCard
                    key={bankAccount.id}
                    bank={bankAccount.bankName}
                    logo={bankAccount.logo}
                    sortCode={bankAccount.sortCode}
                    accountNumber={bankAccount.bankAccountNumber}
                />
                <br />
            </>
        )

        return (
            <Container className={styles.container}>
                <PageContainer title={containerTitle} isLoading={false}>
                    {isLoading ? (
                        <Card loading={isLoading} />
                    ) : !outletDetails ? (
                        <Empty description={false} />
                    ) : (
                        <>
                            <Descriptions
                                className={classNames(styles.descriptionLabel, {
                                    [styles.descriptionLabelMobile]: isMobile
                                })}
                                title=''
                                layout={isMobile ? 'vertical' : 'horizontal'}
                                column={1}
                                colon={false}
                            >
                                <Descriptions.Item label={translations().labels.outletStatus}>
                                    <OutletStatus isActive={outletDetails?.isActive} />
                                </Descriptions.Item>
                                <Descriptions.Item label={translations().labels.address}>
                                    {outletDetails?.address}
                                </Descriptions.Item>
                                <Descriptions.Item label={translations().labels.mid}>
                                    <Typography.Paragraph copyable>
                                        {outletDetails?.mid}
                                    </Typography.Paragraph>
                                </Descriptions.Item>
                            </Descriptions>
                            {outletDetails?.directDebitAccounts?.length > 0 ? (
                                <Typography.Title level={4} className={styles.bankAccount}>
                                    {translations().titles.directDebitAccount}
                                </Typography.Title>
                            ) : null}
                            {outletDetails?.directDebitAccounts?.map((bankAccount) =>
                                renderBankAccount(bankAccount)
                            )}
                            {outletDetails?.settlementAccounts?.length > 0 ? (
                                <Typography.Title level={4} className={styles.bankAccount}>
                                    {translations().titles.settlementsAccount}
                                </Typography.Title>
                            ) : null}
                            {outletDetails?.settlementAccounts?.map((bankAccount) =>
                                renderBankAccount(bankAccount)
                            )}
                        </>
                    )}
                </PageContainer>
            </Container>
        )
    }
)

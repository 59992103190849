import rootTranslations from '@/translations'

export enum OutletStatus {
    active = 'active',
    inactive = 'inactive'
}

export const getPortalOutletStatuses = () =>
    Object.keys(OutletStatus)
        .filter((key) => isNaN(Number(key)))
        .map((item) => ({
            label: rootTranslations().constants.outletStatus[item],
            value: item
        }))

import translations from './translations'

translations.add('es', {
    tabs: {
        all: 'Todo',
        invited: 'Invitado',
        blocked: 'Bloqueado'
    },
    statuses: {
        ok: 'Activo',
        block: 'Bloqueado',
        invited: 'Invitado',
        changePassword: 'Cambiar contraseña',
        validateEmail: 'Validar correo electrónico'
    },
    roles: {
        all: 'Activo',
        admin: 'Admin',
        finance: 'Finanzas',
        custom: 'Personalizado'
    },
    columns: {
        email: 'Email',
        firstName: 'Nombre',
        lastName: 'Apellido',
        status: 'Estado',
        authMethod: 'Método de Autenticación',
        actions: 'Acciones'
    },
    actions: {
        edit: 'Editar',
        delete: 'Borrar',
        resendInvitation: 'Reenviar invitación'
    },
    confirmations: {
        areYouSureToDeleteTeammate: '¿Estás seguro de que quieres borrar a tu compañero de equipo?',
        areYouSureToResendInvitation:
            '¿Estás seguro de que quieres reenviar la invitación a tu compañero de equipo?',
        yes: 'Sí',
        no: 'No'
    },
    successfullyResent: 'Invitación reenviada con éxito',
    successfullyDeleted: 'La invitación se eliminó con éxito',
    successfullyDeletedTeammate: 'Compañero de equipo eliminado con éxito'
})

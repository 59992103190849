import React from 'react'
import {observer} from 'mobx-react'

import {withTitle} from '@/router/utils'
import {OutletListFilterContainer, OutletsContainer} from '@/components'

import translations from './translations'

export const POSOutletsStoreSymbol = Symbol('POSOutletsStoreSymbol')
export const POSOutletsPaginationStoreSymbol = Symbol('POSOutletsPaginationStoreSymbol')
export const POSOutletsStatusStoreSymbol = Symbol('POSOutletsStatusStoreSymbol')
export const POSOutletListFilterStoreSymbol = Symbol('POSOutletListFilterStoreSymbol')

const POSOutlets: React.FC<null> = observer(() => {
    return (
        <OutletsContainer
            injectableIdentifier={POSOutletsStoreSymbol}
            paginationInjectableIdentifier={POSOutletsPaginationStoreSymbol}
            containerTitle={translations().posOutlets}
            extra={
                <OutletListFilterContainer injectableIdentifier={POSOutletListFilterStoreSymbol} />
            }
        />
    )
})

export default withTitle(POSOutlets, translations().posOutlets)

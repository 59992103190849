import {SelectItem} from '@/components/dumb/Select'
import {Role} from './Role'
import translations from '../translations'
import {TeammateStatus} from '@/stores/team-management/constants/TeammateStatus'
import {InviteTeammatePermissions} from '@/pages/TeamManagement/components/InviteEditTeammate/models'

export const teamManagementAllTab = [
    {
        title: translations().tabs.all,
        key: TeammateStatus.ok
    }
]

export const roleSelectOptions: SelectItem[] = [
    ...Object.keys(Role).map((key) => ({
        value: Role[key],
        label: translations().roles[key]
    }))
]

export const financePermissions: InviteTeammatePermissions = {
    overview: 'read',
    online_payments: 'full',
    pos_payments: 'full',
    pos_amex_payments: 'full',
    payment_links: 'no',
    virtual_terminal: 'no',
    settlements: 'full',
    invoices: 'full',
    reports: 'read',
    payment_methods: 'no',
    teammates: 'no',
    chargebacks: 'read',
    'merchant.profile': 'no',
    'merchant.pos_stores': 'no',
    'merchant.ecom_stores': 'no',
    'merchant.bank_accounts': 'no'
}

export const customPermissions: InviteTeammatePermissions = {
    overview: 'no',
    online_payments: 'no',
    pos_payments: 'no',
    pos_amex_payments: 'no',
    payment_links: 'no',
    virtual_terminal: 'no',
    settlements: 'no',
    invoices: 'no',
    reports: 'no',
    payment_methods: 'no',
    teammates: 'no',
    chargebacks: 'no',
    'merchant.profile': 'no',
    'merchant.pos_stores': 'no',
    'merchant.ecom_stores': 'no',
    'merchant.bank_accounts': 'no'
}

import {PayPalOnboardingUrlConfig, PayPalReturnUrlQuery} from '../models'
import {ROUTES} from '@/router/routes'

export const getReturnUrlFields = (query: PayPalReturnUrlQuery) => {
    const fields = [
        'merchantId',
        'merchantIdInPayPal',
        'permissionsGranted',
        'consentStatus',
        'productIntentId',
        'productIntentID',
        'isEmailConfirmed',
        'accountStatus',
        'riskStatus'
    ]

    return fields.map((f) => ({
        title: f,
        value: query[f]
    }))
}

export const loadPaypalScript = () => {
    const d = document
    const s = 'script'
    const id = 'paypal-js'

    let js: HTMLScriptElement = null
    const ref = d.getElementsByTagName(s)[0]

    if (!d.getElementById(id)) {
        js = d.createElement(s)
        js.id = id
        js.async = true
        js.src = 'https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js'
        ref.parentNode.insertBefore(js, ref)
    }
}

export const generatePath = (route: string, params: Record<string, string> = {}) => {
    return Object.entries(params).reduce((path, [key, value]) => {
        return path.replace(`:${key}`, value)
    }, route)
}

export const buildUrl = (path: string, {protocol, hostname, port}: PayPalOnboardingUrlConfig) => {
    const portStr = port ? `:${port}` : ''
    return `${protocol}//${hostname}${portStr}${path}`
}

export const createPayPalReturnUrl = (config: PayPalOnboardingUrlConfig) => {
    const path = generatePath(ROUTES.settings.paymentMethodDetails, {type: 'paypal'})
    return buildUrl(path, config)
}

import translations from './translations'

translations.add('ro', {
    columnHeader: {
        business: 'Afacere',
        outletsNProducts: 'Puncte de Vânzare & Produse',
        other: 'Altele'
    },
    cardTitle: {
        companyProfile: 'Profil Companie',
        bankAccounts: 'Conturi Bancare',
        posOutlets: 'Puncte de Vânzare POS',
        onlineOutlets: 'Puncte de Vânzare Online',
        teamManagement: 'Managementul Echipei',
        paymentMethods: 'Metode de Plată Online'
    },
    cardDescription: {
        companyProfile: 'Detalii companie',
        bankAccounts: 'Debit direct și decontări',
        posOutlets: 'Magazine fizice',
        onlineOutlets: 'Magazine online',
        teamManagement: 'Gestionați utilizatorii și rolurile',
        paymentMethods: 'Metode de Plată pentru Comerț Electronic'
    }
})

import React from 'react'
import {Icon} from '@/components/dumb/Icon'
import translations from '@/translations'
import {MenuDataItemWithProduct} from '@/stores/auth/types/MenuDataItemWithProduct'
import {routesProductsMap} from '@/router/routes-products-map'
import {USER_GUIDE_SELECTORS} from '@/constants'

import {ROUTES} from './routes'
import {routesPermissionMap} from './routes-permission-map'

export const menu: MenuDataItemWithProduct[] = ([] = [
    {
        icon: <Icon type='home' />,
        title: translations().pages.overview,
        path: ROUTES.home,
        permissions: routesPermissionMap[ROUTES.home],
        products: routesProductsMap[ROUTES.home],
        className: USER_GUIDE_SELECTORS.overview
    },
    {
        icon: <Icon type='pos_transactions' />,
        title: translations().pages.posPayments,
        path: ROUTES.posPayments,
        permissions: routesPermissionMap[ROUTES.posPayments],
        products: routesProductsMap[ROUTES.posPayments],
        className: USER_GUIDE_SELECTORS.posPayments
    },
    {
        icon: <Icon type='transactions' />,
        title: translations().pages.onlinePayments,
        path: ROUTES.onlinePayments,
        permissions: routesPermissionMap[ROUTES.onlinePayments],
        products: routesProductsMap[ROUTES.onlinePayments],
        className: USER_GUIDE_SELECTORS.onlinePayments
    },
    {
        icon: <Icon type='payment_links' />,
        title: translations().pages.paymentLinks,
        path: ROUTES.paymentLinks,
        permissions: routesPermissionMap[ROUTES.paymentLinks],
        products: routesProductsMap[ROUTES.paymentLinks],
        className: USER_GUIDE_SELECTORS.paymentLinks
    },
    {
        icon: <Icon type='virtual_terminal' />,
        title: translations().pages.virtualTerminal,
        path: ROUTES.virtualTerminal,
        permissions: routesPermissionMap[ROUTES.virtualTerminal],
        products: routesProductsMap[ROUTES.virtualTerminal],
        className: USER_GUIDE_SELECTORS.virtualTerminal
    },
    {
        icon: <Icon type='amex_transactions' />,
        title: translations().pages.posAmexPayments,
        path: ROUTES.posAmexPayments,
        permissions: routesPermissionMap[ROUTES.posAmexPayments],
        products: routesProductsMap[ROUTES.posAmexPayments],
        className: USER_GUIDE_SELECTORS.posAmexPayments
    },
    {
        icon: <Icon type='chargebacks' />,
        title: translations().pages.chargebacks,
        path: ROUTES.chargebacks,
        permissions: routesPermissionMap[ROUTES.chargebacks],
        products: routesProductsMap[ROUTES.chargebacks],
        className: USER_GUIDE_SELECTORS.chargebacks
    },
    {
        icon: <Icon type='settlements' />,
        title: translations().pages.settlements,
        path: ROUTES.settlements,
        permissions: routesPermissionMap[ROUTES.settlements],
        products: routesProductsMap[ROUTES.settlements],
        className: USER_GUIDE_SELECTORS.settlements
    },
    {
        icon: <Icon type='settlements' />,
        title: translations().pages.billing,
        permissions: routesPermissionMap[ROUTES.billing.invoices],
        products: routesProductsMap[ROUTES.billing.invoices],
        children: [
            {
                title: translations().pages.invoices,
                path: ROUTES.billing.invoices,
                permissions: routesPermissionMap[ROUTES.billing.invoices],
                products: routesProductsMap[ROUTES.billing.invoices],
                className: USER_GUIDE_SELECTORS.invoices
            }
        ]
    },
    {
        icon: <Icon type='reports' />,
        title: translations().pages.reports,
        path: ROUTES.reports,
        permissions: routesPermissionMap[ROUTES.reports],
        products: routesProductsMap[ROUTES.reports],
        className: USER_GUIDE_SELECTORS.reports
    },
    // {
    //     icon: <Icon type='card_payments' />,
    //     title: translations().pages.paymentMethods,
    //     path: ROUTES.paymentMethods,
    //     permissions: routesPermissionMap[ROUTES.paymentMethods],
    //     products: routesProductsMap[ROUTES.paymentMethods],
    //     className: USER_GUIDE_SELECTORS.paymentMethods
    // },
    // {
    //     icon: <Icon type='team' />,
    //     title: translations().pages.teamManagement,
    //     path: ROUTES.teamManagement,
    //     permissions: routesPermissionMap[ROUTES.teamManagement],
    //     products: routesProductsMap[ROUTES.teamManagement],
    //     className: USER_GUIDE_SELECTORS.teamManagement
    // },
    {
        icon: <Icon type='download' />,
        title: translations().pages.exports,
        path: ROUTES.exports,
        permissions: routesPermissionMap[ROUTES.exports],
        products: routesProductsMap[ROUTES.exports],
        className: USER_GUIDE_SELECTORS.exports
    },
    {
        icon: <Icon type='settings' />,
        title: translations().pages.settings,
        path: ROUTES.settings.base,
        permissions: routesPermissionMap[ROUTES.settings.base],
        products: routesProductsMap[ROUTES.settings.base]
    },
    {
        icon: <Icon type='help' />,
        title: translations().pages.help,
        path: 'https://dnapayments.com/faq'
    }
])

import React from 'react'
import {observer} from 'mobx-react'

import {withTitle} from '@/router/utils'
import {OutletListFilterContainer, OutletsContainer} from '@/components'

import translations from './translations'

export const OnlineOutletsStoreSymbol = Symbol('OnlineOutletsStoreSymbol')
export const OnlineOutletsPaginationStoreSymbol = Symbol('OnlineOutletsPaginationStoreSymbol')
export const OnlineOutletListFilterStoreSymbol = Symbol('OnlineOutletListFilterStoreSymbol')
export const OnlineOutletsStatusStoreSymbol = Symbol('OnlineOutletsStatusStoreSymbol')

const OnlineOutlets: React.FC<null> = observer(() => {
    return (
        <OutletsContainer
            injectableIdentifier={OnlineOutletsStoreSymbol}
            paginationInjectableIdentifier={OnlineOutletsPaginationStoreSymbol}
            containerTitle={translations().onlineOutlets}
            extra={
                <OutletListFilterContainer
                    injectableIdentifier={OnlineOutletListFilterStoreSymbol}
                />
            }
        />
    )
})

export default withTitle(OnlineOutlets, translations().onlineOutlets)

import {getWithAuth} from '@/api'
import {TerminalType} from '@/constants'
import {OutletDetailsResponseModel, OutletsResponseModel} from '@/stores/outlets'
import {OutletsRequestFilter} from '@/stores/outlets/models/OutletsRequestFilter'

export const fetchDossierMerchantOutlets = ({
    type,
    page,
    size,
    isActive,
    mid
}: OutletsRequestFilter) =>
    getWithAuth<OutletsResponseModel>(
        `/oppapi/v1/merchant-info/stores?${[
            type === TerminalType.ALL ? '' : `type=${type}`,
            page !== undefined ? `page=${page}` : '',
            size !== undefined ? `size=${size}` : '',
            isActive !== undefined ? `isActive=${isActive}` : '',
            mid !== undefined ? `mid=${mid}` : ''
        ]
            .filter(Boolean)
            .join('&')}`
    )

export const fetchDossierMerchantOutletDetails = (id: string) =>
    getWithAuth<OutletDetailsResponseModel>(`/oppapi/v1/merchant-info/stores/${id}`)

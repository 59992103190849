import {inject, injectable} from 'inversify'

import {ISettingsStore} from '@/pages/Settings'
import {SettingsColumnItem} from '@/stores/settings'
import {RouterStore, RouterStoreSymbol} from '@/router/RouterStore'
import {ROUTES} from '@/router/routes'

import translations from './translations'
import {hasPermissions} from '@/stores/auth/services'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {
    DossierMerchantInfoStore,
    DossierMerchantInfoStoreSymbol
} from '@/stores/profile/DossierMerchantInfo'

@injectable()
export class SettingsStore implements ISettingsStore {
    routerStore: RouterStore
    dossierMerchantStore: DossierMerchantInfoStore

    constructor(
        @inject(RouterStoreSymbol) routerStore: RouterStore,
        @inject(DossierMerchantInfoStoreSymbol) dossierMerchantStore: DossierMerchantInfoStore
    ) {
        this.routerStore = routerStore
        this.dossierMerchantStore = dossierMerchantStore
    }

    getColumns = (): SettingsColumnItem[] => {
        return [
            ...(hasPermissions([
                PermissionsMap.merchant.profile.read,
                PermissionsMap.merchant.profile.full,
                PermissionsMap.merchant.bank_accounts.read,
                PermissionsMap.merchant.bank_accounts.full
            ])
                ? [
                      {
                          title: translations().columnHeader.business,
                          items: [
                              ...(hasPermissions([
                                  PermissionsMap.merchant.profile.read,
                                  PermissionsMap.merchant.profile.full
                              ]) && this.dossierMerchantStore?.dossierMerchantData?.mainInfo
                                  ? [
                                        {
                                            title: translations().cardTitle.companyProfile,
                                            description:
                                                translations().cardDescription.companyProfile,
                                            onClick: () =>
                                                this.onCardClick(ROUTES.settings.companyProfile)
                                        }
                                    ]
                                  : []),
                              ...(hasPermissions([
                                  PermissionsMap.merchant.bank_accounts.read,
                                  PermissionsMap.merchant.bank_accounts.full
                              ])
                                  ? [
                                        {
                                            title: translations().cardTitle.bankAccounts,
                                            description:
                                                translations().cardDescription.bankAccounts,
                                            onClick: () =>
                                                this.onCardClick(ROUTES.settings.bankAccounts)
                                        }
                                    ]
                                  : [])
                          ]
                      }
                  ]
                : []),
            ...(hasPermissions([
                PermissionsMap.merchant.ecom_stores.read,
                PermissionsMap.merchant.ecom_stores.full,
                PermissionsMap.merchant.pos_stores.read,
                PermissionsMap.merchant.pos_stores.full,
                PermissionsMap.payment_methods.read,
                PermissionsMap.payment_methods.full
            ])
                ? [
                      {
                          title: translations().columnHeader.outletsNProducts,
                          items: [
                              ...(hasPermissions([
                                  PermissionsMap.merchant.pos_stores.read,
                                  PermissionsMap.merchant.pos_stores.full
                              ])
                                  ? [
                                        {
                                            title: translations().cardTitle.posOutlets,
                                            description: translations().cardDescription.posOutlets,
                                            onClick: () =>
                                                this.onCardClick(ROUTES.settings.posOutlets)
                                        }
                                    ]
                                  : []),
                              ...(hasPermissions([
                                  PermissionsMap.merchant.ecom_stores.read,
                                  PermissionsMap.merchant.ecom_stores.full
                              ])
                                  ? [
                                        {
                                            title: translations().cardTitle.onlineOutlets,
                                            description:
                                                translations().cardDescription.onlineOutlets,
                                            onClick: () =>
                                                this.onCardClick(ROUTES.settings.onlineOutlets)
                                        }
                                    ]
                                  : []),
                              ...(hasPermissions([
                                  PermissionsMap.payment_methods.read,
                                  PermissionsMap.payment_methods.full
                              ])
                                  ? [
                                        {
                                            title: translations().cardTitle.paymentMethods,
                                            description:
                                                translations().cardDescription.paymentMethods,
                                            onClick: () =>
                                                this.onCardClick(ROUTES.settings.paymentMethods)
                                        }
                                    ]
                                  : [])
                          ]
                      }
                  ]
                : []),
            ...(hasPermissions([PermissionsMap.teammates.read, PermissionsMap.teammates.full])
                ? [
                      {
                          title: translations().columnHeader.other,
                          items: [
                              ...(hasPermissions([
                                  PermissionsMap.teammates.read,
                                  PermissionsMap.teammates.full
                              ])
                                  ? [
                                        {
                                            title: translations().cardTitle.teamManagement,
                                            description:
                                                translations().cardDescription.teamManagement,
                                            onClick: () =>
                                                this.onCardClick(ROUTES.settings.teamManagement)
                                        }
                                    ]
                                  : [])
                          ]
                      }
                  ]
                : [])
        ]
    }

    onCardClick = (route: string) => {
        this.routerStore.push(route)
    }
}

import translations from './translations'

translations.add('el', {
    columnHeader: {
        business: 'Επιχείρηση',
        outletsNProducts: 'Καταστήματα & Προϊόντα',
        other: 'Άλλο'
    },
    cardTitle: {
        companyProfile: 'Προφίλ Εταιρείας',
        bankAccounts: 'Τραπεζικοί Λογαριασμοί',
        posOutlets: 'Καταστήματα POS',
        onlineOutlets: 'Ηλεκτρονικά Καταστήματα',
        teamManagement: 'Διαχείριση Ομάδας',
        paymentMethods: 'Διαδικτυακές Μέθοδοι Πληρωμής'
    },
    cardDescription: {
        companyProfile: 'Τα στοιχεία της εταιρείας σας',
        bankAccounts: 'Απευθείας χρέωση και διακανονισμοί',
        posOutlets: 'Καταστήματα πρόσωπο με πρόσωπο',
        onlineOutlets: 'Ηλεκτρονικά καταστήματα',
        teamManagement: 'Διαχείριση χρηστών και ρόλων',
        paymentMethods: 'Μέθοδοι Πληρωμής για Ηλεκτρονικό Εμπόριο'
    }
})

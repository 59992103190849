import React from 'react'
import classNames from 'classnames'
import icons from '~/assets/icons'
import {IconProps} from './props'
import styles from './styles.scss'

export const Icon: React.FC<IconProps> = ({type, className, hoverable, rotate = 0, ...rest}) => (
    <span
        className={classNames(styles.icon, className, {
            [styles.hoverable]: hoverable
        })}
        style={{
            transform: `rotate(${rotate}deg)`,
            transition: 'transform 0.2s ease'
        }}
        {...rest}
    >
        {icons[type]}
    </span>
)

import {TranslationBuilder} from '@/components/dumb/Translation'
import {Role} from '../constants/Role'
import {TeammateStatus} from '@/stores/team-management/constants'

export default TranslationBuilder.create<{
    tabs: {
        all: string
        invited: string
        blocked: string
    }
    statuses: {
        [key in keyof typeof TeammateStatus]: string
    }
    roles: {
        [key in keyof typeof Role]: string
    }
    columns: {
        email: string
        firstName: string
        lastName: string
        status: string
        authMethod: string
        actions: string
    }
    actions: {
        edit: string
        delete: string
        resendInvitation: string
    }
    confirmations: {
        areYouSureToDeleteTeammate: string
        areYouSureToResendInvitation: string
        yes: string
        no: string
    },
    successfullyResent: string
    successfullyDeleted: string
    successfullyDeletedTeammate: string
}>()

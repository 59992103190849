import {ROUTES} from '@/router/routes'

export const PLATFORM = 'mp'

export const SECTION_ROUTES = {
    app: ROUTES.home,
    overview: ROUTES.home,
    pos_payments: ROUTES.posPayments,
    online_payments: ROUTES.onlinePayments,
    payment_links: ROUTES.paymentLinks,
    virtual_terminal: ROUTES.virtualTerminal,
    pos_american_express: ROUTES.posAmexPayments,
    settlements: ROUTES.settlements,
    invoices: ROUTES.billing.invoices,
    reports: ROUTES.reports,
    payment_methods: ROUTES.settings.paymentMethods,
    team_management: ROUTES.settings.teamManagement,
    exports: ROUTES.exports,
    login: ROUTES.login,
    profile: ROUTES.profile
}

import React from 'react'
import {observer} from 'mobx-react'
import classNames from 'classnames'
import {Col, Divider, Row, Typography} from 'antd'
import {useInjection} from 'dna-react-ioc'
import {RightOutlined} from '@ant-design/icons'

import {useScreenType} from '@/hooks'
import {withTitle} from '@/router/utils'
import {Card, Container, PageHeader} from '@/components'

import {ISettingsStore} from './ISettingsStore'
import translations from './translations'
import styles from './Settings.scss'

export const SettingsStoreSymbol = Symbol.for('SettingsStoreSymbol')

const Settings: React.FC<null> = observer(() => {
    const {getColumns} = useInjection<ISettingsStore>(SettingsStoreSymbol)
    const {isMobile} = useScreenType()
    const columns = getColumns()

    return (
        <>
            <PageHeader
                onBack={null}
                title={
                    <Typography.Title level={4} className={styles.headerTitle}>
                        {translations().settings}
                    </Typography.Title>
                }
                className={styles.header}
                border={null}
            />
            <Divider className={classNames(styles.divider, {[styles.dividerMobile]: isMobile})} />
            <Container>
                <Row gutter={[16, 16]}>
                    {columns.map((column) => {
                        return (
                            <Col
                                key={column.title}
                                xs={24}
                                sm={8}
                                md={8}
                                lg={columns.length > 2 ? 8 : 12}
                            >
                                <div key={column.title}>
                                    <Typography.Title level={4}>{column.title}</Typography.Title>
                                    {column.items.map((item, index) => {
                                        return (
                                            <>
                                                <Card
                                                    className={styles.card}
                                                    onClick={item.onClick}
                                                    hoverable
                                                    title={
                                                        <Typography.Title
                                                            level={4}
                                                            className={styles.cardHeader}
                                                        >
                                                            {item.title}
                                                        </Typography.Title>
                                                    }
                                                    key={item.title}
                                                    extra={<RightOutlined />}
                                                >
                                                    <span className={styles.cardDescription}>
                                                        {item.description}
                                                    </span>
                                                </Card>
                                                {column.items.length - 1 !== index && <br />}
                                            </>
                                        )
                                    })}
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </>
    )
})

export default withTitle(Settings)

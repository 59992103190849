import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    columnHeader: {
        business: string
        outletsNProducts: string
        other: string
    }
    cardTitle: {
        companyProfile: string
        bankAccounts: string
        posOutlets: string
        onlineOutlets: string
        teamManagement: string
        paymentMethods: string
    }
    cardDescription: {
        companyProfile: string
        bankAccounts: string
        posOutlets: string
        onlineOutlets: string
        teamManagement: string
        paymentMethods: string
    }
}>()

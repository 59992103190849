import React, {PropsWithChildren} from 'react'
import classNames from 'classnames'

import {useScreenType} from '@/hooks'

import {ContainerProps} from './props'
import styles from './Container.scss'

export const Container: React.FC<PropsWithChildren<ContainerProps>> = ({children, className}) => {
    const {isMobile} = useScreenType()
    return (
        <div
            className={classNames(styles.container, className, {
                [styles.containerMobile]: isMobile
            })}
        >
            {children}
        </div>
    )
}

import 'reflect-metadata'
import {injectable} from 'inversify'
import {TPaymentMethodsStore} from '@/pages/payment-methods/PaymentMethods/TPaymentMethodsStore'
import {PaymentMethodItem} from '@/stores/payment-methods/PaymentMethods/models'
import {paymentMethods} from './constants'
import {ROUTES} from '@/router/routes'
import {getRouterStore} from '@/router/utils'

@injectable()
export class PaymentMethodsStore implements TPaymentMethodsStore {
    paymentMethods: PaymentMethodItem[] = paymentMethods

    onBack = () => {
        getRouterStore().push(ROUTES.settings.base)
    }
}

import React from 'react'
import {Tooltip} from 'antd'
import classNames from 'classnames'

import {Icon} from '@/components'

import {IconTitleProps} from './props'
import styles from './styles.scss'

export const IconTitle: React.FC<IconTitleProps> = ({
    title,
    tooltipText,
    className,
    iconType = 'info'
}) => {
    return (
        <div className={classNames(styles.titleWrapper, className)}>
            <div className={styles.title}>{title}</div>
            {tooltipText ? (
                <Tooltip title={tooltipText}>
                    <Icon className={styles.icon} type={iconType} />
                </Tooltip>
            ) : null}
        </div>
    )
}

import translations from './translations'

translations.add('sl', {
    title: 'Riadenie tímu',
    inviteTeammate: 'Pridať používateľa',
    editTeammate: 'Upraviť používateľa',
    cancel: 'Zruš',
    submit: 'Kompletné',
    invite: 'Pozvať',
    formFields: {
        email: 'Email',
        store: 'Obchod',
        firstName: 'Krstné meno',
        lastName: 'Priezvisko',
        teammatePermissions: 'Prístup používateľa',
        inviteAsAdmin: 'Admin',
        inviteAsRestrictedAccess: 'Obmedzený prístup',
        accountantAccess: 'Prístup k financiám',
        customAccess: 'Vlastný prístup',
        grantedPermissions: 'Nastavenia povolení',
        accessLevels: 'úrovne prístupu',
        sectionName: 'Názov oddielu',
        noAccess: 'Žiadny prístup',
        readAccess: 'Iba na čítanie',
        fullAccess: 'Úplný prístup',
        sections: {
            overview: 'Prehľad',
            online_payments: 'Online platby',
            pos_payments: 'POS platby',
            pos_amex_payments: 'POS American Express',
            payment_links: 'Platobné prepojenia',
            virtual_terminal: 'Virtuálny terminál',
            settlements: 'Zúčtovanie, platba',
            invoices: 'Faktúry',
            reports: 'Správy',
            payment_methods: 'Nastavenia. Online Spôsoby Platby',
            teammates: 'Nastavenia. Riadenie Tímu',
            chargebacks: 'Vračila',
            'merchant.profile': 'Nastavenia. Profil spoločnosti',
            'merchant.ecom_stores': 'Nastavenia. Internetové obchody',
            'merchant.pos_stores': 'Nastavenia. POS Prevádzky',
            'merchant.bank_accounts': 'Nastavenia. Bankové účty'
        }
    },
    formFieldsPlaceholders: {
        role: 'Select role'
    },
    formErrors: {
        emailRequired: 'Vyžaduje sa e-mail',
        roleRequired: 'Vyžaduje sa rola',
        shopRequired: 'Vyžaduje sa obchod',
        enterValidEmail: 'Zadajte platný e-mail',
        firstNameRequired: 'Vyžaduje sa krstné meno',
        lastNameRequired: 'Vyžaduje sa priezvisko'
    }
})

import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Empty} from 'antd'

import {withTitle} from '@/router/utils'
import {Card, Container, PageContainer} from '@/components'

import {BankAccountCard} from './components'
import {IBankAccountsStore} from './IBankAccountsStore'
import translations from './translations'
import styles from './BankAccounts.scss'

export const BankAccountsStoreSymbol = Symbol('BankAccountsStoreSymbol')

const BankAccounts: React.FC<null> = observer(() => {
    const {bankAccounts, isLoading, onBankCardClick} =
        useInjection<IBankAccountsStore>(BankAccountsStoreSymbol)

    return (
        <Container className={styles.container}>
            <PageContainer title={translations().bankAccounts} isLoading={false}>
                {isLoading && <Card loading={isLoading} />}
                {bankAccounts.map((bankAccount, index) => (
                    <>
                        <BankAccountCard
                            key={bankAccount.accountNumber}
                            accountName={bankAccount.bankDetail?.bankAccountName}
                            accountNumber={bankAccount.accountNumber}
                            bank={bankAccount.bankDetail?.bankName}
                            sortCode={bankAccount.bankDetail?.sortCode}
                            logo={bankAccount.bankDetail?.logo}
                            associatedSettlementsOutlets={bankAccount.stores.map(
                                (store) => store.storeName
                            )}
                            accountTypes={bankAccount.accountTypes}
                            onClick={() => onBankCardClick(index)}
                        />
                        <br />
                    </>
                ))}
                {bankAccounts.length === 0 && !isLoading && <Empty description={false} />}
            </PageContainer>
        </Container>
    )
})

export default withTitle(BankAccounts, translations().bankAccounts)

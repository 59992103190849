import {injectable} from 'inversify'
import {computed, makeObservable, reaction} from 'mobx'

import {StatusStore} from '@/stores'
import {BaseFilterContainerStore} from '@/stores/shared'
import {FilterFormFieldType} from '@/types'
import {OutletListFilterType} from '@/stores/outlets'
import rootTranslations from '@/translations/translations'
import {getPortalOutletStatuses, OutletStatus} from '@/constants/outlet-status'
import {IOutletListFilterContainerStore} from '@/components'

@injectable()
export class OutletListFilterStore
    extends BaseFilterContainerStore<OutletListFilterType>
    implements IOutletListFilterContainerStore
{
    statusSelectStore: StatusStore = new StatusStore()

    constructor() {
        super()

        makeObservable(this, {
            ...super.getAnnotationsMap(),
            isActive: computed
        })

        this.statusSelectStore.setOptions(getPortalOutletStatuses())
        this.statusSelectStore.setValue(OutletStatus.active)

        reaction(
            () => this.statusSelectStore.value,
            () => {
                this.submit()
            }
        )
    }

    get filter() {
        return {
            ...this._filter
        }
    }

    get fields(): FilterFormFieldType[] {
        return [
            {
                name: 'mid',
                label: rootTranslations().fields.mid,
                type: 'input'
            }
        ]
    }

    get isActive(): boolean {
        return this.statusSelectStore.value === OutletStatus.active
    }

    getInitialFilter() {
        return {
            mid: ''
        }
    }

    getFilterForBackend() {
        return {
            ...this.filter,
            isActive: this.isActive
        }
    }
}

import React from 'react'
import {Link} from 'react-router-dom'
import {ROUTES} from '@/router/routes'
import {TransactionType} from '@/types/transactions'
import {POSPaymentStateStatus} from '@/constants/pos-payment-state-status'
import {OnlinePaymentStatus} from '@/constants/online-payment-status'
import {ChargebackStatus} from '@/constants/chargeback-status'
import {ResonCodeCategory} from '@/constants/reason-code-category'
import {PaymentCardType} from '@/constants/payment-card-type'
import {CardSchemeType} from '@/constants/card-scheme'
import translations from './translations'

translations.add('en', {
    siteTitle: 'Merchant Portal DNA Payments',
    pages: {
        home: 'Home',
        activation: 'Activation',
        overview: 'Overview',
        paymentLinks: 'Payment links',
        posPayments: 'POS payments',
        posAmexPayments: 'POS American Express',
        onlinePayments: 'Online payments',
        chargebacks: 'Chargebacks',
        virtualTerminal: 'Virtual terminal',
        settlements: 'Settlements',
        billing: 'Billing',
        invoices: 'Invoices',
        reports: 'Reports',
        paymentMethods: 'Payment methods',
        payPal: 'PayPal',
        payByBank: 'Pay by Bank',
        openBanking: 'Open Banking',
        settings: 'Settings',
        developers: 'Developers',
        checkoutCustomization: 'Checkout customization',
        tariffs: 'Tariffs',
        profile: 'Profile',
        login: 'Login',
        resetPassword: 'Reset password',
        twoFA: 'Two-factor authentication',
        twoFAEnforced: 'Two-factor authentication has been enforced',
        recoveryCode: 'Recovery code',
        chooseCompany: 'Choose company',
        confirmEmail: 'Confirm email',
        confirmTwoFA: 'Two-factor authentication',
        cardsManagement: 'Cards management',
        makeTransfer: 'Make transfer',
        setPassword: 'Set password',
        teamManagement: 'Team management',
        noPermissions: 'No Permissions',
        help: 'Help',
        ecospendReturn: 'Ecospend return',
        noPage: 'No page',
        accountInfo: 'Account info',
        exports: 'Exports',
        companyProfile: 'Company profile',
        bankAccounts: 'Bank accounts',
        posOutlets: 'POS outlets',
        onlineOutlets: 'Online outlets'
    },
    constants: {
        onlinePaymentStatus: {
            [OnlinePaymentStatus.all]: 'All',
            [OnlinePaymentStatus.auth]: 'Pending',
            [OnlinePaymentStatus.created]: 'Created',
            [OnlinePaymentStatus.credited]: 'Credited',
            [OnlinePaymentStatus.charge]: 'Charged',
            [OnlinePaymentStatus.cancel]: 'Cancelled',
            [OnlinePaymentStatus.refund]: 'Refunded',
            [OnlinePaymentStatus.reject]: 'Declined',
            [OnlinePaymentStatus.failed]: 'Failed',
            [OnlinePaymentStatus.new]: 'New',
            [OnlinePaymentStatus.threeDSecure]: '3D secure',
            [OnlinePaymentStatus.tokenized]: 'Tokenized',
            [OnlinePaymentStatus.verified]: 'Verified',
            [OnlinePaymentStatus.processing]: 'Processing',
            [OnlinePaymentStatus.abandoned]: 'Abandoned',
            [OnlinePaymentStatus.other]: 'Other'
        },
        chargebackStatus: {
            [ChargebackStatus.all]: 'All',
            [ChargebackStatus.chargeback]: 'Chargeback',
            [ChargebackStatus['chargeback-reversal']]: 'Chargeback Reversal',
            [ChargebackStatus['second-chargeback-lost']]: 'Second Chargeback - Lost'
        },
        reasonCodeCategory: {
            [ResonCodeCategory['processing-errors']]: 'Processing Errors',
            [ResonCodeCategory['fraud']]: 'Fraud',
            [ResonCodeCategory['consumer']]: 'Consumer',
            [ResonCodeCategory['authorisation']]: 'Authorisation'
        },
        posPaymentStatus: {
            all: 'All',
            authorised: 'Authorised',
            cancelled: 'Cancelled',
            charged: 'Charged',
            declined: 'Declined',
            refunded: 'Refunded',
            chargeback: 'Chargeback',
            'chargeback-reversal': 'Chargeback reversal',
            'second-chargeback-lost': 'Second chargeback - lost'
        },
        outletStatus: {
            active: 'Active',
            inactive: 'Inactive'
        },
        posPaymentStateStatus: {
            [POSPaymentStateStatus.all]: 'All',
            [POSPaymentStateStatus.successful]: 'Successful',
            [POSPaymentStateStatus.failed]: 'Declined'
        },
        payByLinkStatus: {
            active: 'Active',
            expired: 'Expired',
            paid: 'Paid',
            cancelled: 'Cancelled',
            viewed: 'Viewed',
            attempted: 'Attempted',
            all: 'All'
        },
        paymentMethodType: {
            card: 'Card',
            paypal: 'PayPal',
            applepay: 'Apple Pay',
            googlepay: 'Google Pay',
            ecospend: 'Open Banking',
            paybybankapp: 'Pay by Bank',
            klarna: 'Klarna',
            clicktopay: 'ClickToPay',
            astropay: 'AstroPay',
            alipay: 'Alipay',
            wechatpay: 'WeChat Pay'
        },
        fileType: {
            PDF: 'PDF',
            EXCEL: 'EXCEL',
            CSV: 'CSV'
        },
        issuerRegion: {
            Domestic: 'Domestic',
            Intra: 'Intra',
            International: 'Intra'
        },
        cardType: {
            [PaymentCardType.Credit]: 'Credit',
            [PaymentCardType.Debit]: 'Debit'
        },
        cardScheme: {
            [CardSchemeType.Amex]: 'American Express',
            [CardSchemeType.Diners]: 'Diners Club',
            [CardSchemeType.Mastercard]: 'MasterCard',
            [CardSchemeType.UnionPay]: 'UnionPay',
            [CardSchemeType.Visa]: 'Visa'
        },
        captureMethods: {
            'ecom-paybybankapp': 'Pay by Bank app',
            'ecom-openbanking': 'Open Banking',
            'ecom-klarna': 'Klarna',
            'pos-contactless': 'POS Contactless',
            'pos-openbanking': 'Open Banking',
            'pos-alipay': 'Alipay',
            ecom: 'ECOM',
            pos: 'POS',
            stored: 'Stored',
            moto: 'MOTO',
            recurring: 'Recurring'
        },
        terminalTypes: {
            all: 'All',
            ecom: 'ECOM',
            pos: 'POS'
        }
    },
    filter: {
        all: 'All',
        allAll: 'All/All'
    },
    success: {
        paymentChargedSuccessfully: 'Payment charged successfully',
        paymentRefundedSuccessfully: 'Payment partial refund was successful',
        paymentRefundedFullySuccessfully: 'Payment full refund was successful',
        newPaymentProcessedSuccessfully: 'Process new payment was successful',
        sendReceiptSuccess: 'Receipt has been sent successfully.',
        downloadReceiptSuccess: 'Receipt has been downloaded successfully.'
    },
    errors: {
        refundProcessing: 'The refund transaction is currently being processed.',
        unknown: 'Ooops, something went wrong. Our team is already looking into this.',
        errorOccured: 'An error occurred, please try again later.',
        general:
            'Sorry, we are unable to process the request at this time. Please try again later.',
        maxAttempt: (count) => `Max ${count} attempts reached.`,
        INVALID_CARD_NUMBER: 'Invalid card number',
        INVALID_CARDHOLDER_NAME: 'Invalid cardholder name',
        INVALID_SECURE_CODE: 'Invalid secure code',
        INVALID_EXPIRY_DATE: 'Invalid expiry date'
    },
    transactionTypes: {
        [TransactionType.REFUND]: 'Refund',
        [TransactionType.ADJUSTMENT]: 'Adjustment',
        [TransactionType.OTHER]: 'Other',
        [TransactionType.PAYBYLINK]: 'Pay by Link',
        [TransactionType.RETAIL]: 'Sale',
        [TransactionType.SALE]: 'Sale',
        [TransactionType.INIT]: 'Init',
        [TransactionType.VERIFICATION]: 'Verification',
        [TransactionType.TOKENIZATION]: 'Tokenization',
        [TransactionType.P2P]: 'P2P',
        [TransactionType.RECURRING]: 'Recurring',
        [TransactionType.AUTH]: 'Authorisation only',
        [TransactionType.PREAUTH as any]: 'Pre-authorisation'
    },
    transactionTypesTooltip: {
        [TransactionType.SALE]:
            '“Sale” - the transaction will be authorised and settled automatically',
        [TransactionType.AUTH]:
            '“Authorisation only” - funds reservation with further manual settlement (Charge function)',
        [TransactionType.VERIFICATION]: '“Verification only” - account verification only',
        [TransactionType.PREAUTH as any]:
            '“Pre-authorization” - funds pre-authorisation with further manual settlement (Charge function)'
    },
    languages: {
        english: 'English',
        spanish: 'Español',
        greek: 'Ελληνικά',
        romanian: 'Română',
        slovak: 'Slovenský'
    },
    action: {
        title: 'Action',
        viewDetails: 'View details'
    },
    fields: {
        accountNumber: 'Account number',
        cardMask: 'Card mask',
        cardScheme: 'Card scheme',
        captureMethod: 'Capture method',
        currency: 'Currency',
        mid: 'MID',
        status: 'Status',
        store: 'Store',
        terminalId: 'Terminal ID',
        terminalType: 'Terminal type',
        transactionId: 'Transaction ID'
    },
    messages: {
        successExportMessage: (
            <span>
                Your download request has been queued for processing. Once the document is ready, it
                can be downloaded from the <Link to={ROUTES.exports}>Exports</Link> section.
            </span>
        ),
        validation: {
            minLength: (n) => `Enter at least ${n} characters`
        }
    }
})

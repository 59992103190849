import translations from './translations'

translations.add('sl', {
    tabs: {
        all: 'Všetko',
        invited: 'Pozvaný',
        blocked: 'Zablokovaný'
    },
    statuses: {
        ok: 'Aktívny',
        block: 'Zablokovaný',
        invited: 'Pozvaný',
        changePassword: 'Zmeň heslo',
        validateEmail: 'Over email'
    },
    roles: {
        all: 'Všetko',
        admin: 'Admin',
        finance: 'Finance',
        custom: 'Vlastné'
    },
    columns: {
        email: 'Email',
        firstName: 'Krstné meno',
        lastName: 'Priezvisko',
        status: 'Stav',
        authMethod: 'Metoda Preverjanja',
        actions: 'Akcie'
    },
    actions: {
        edit: 'Uprav',
        delete: 'Zmaž',
        resendInvitation: 'Pošli znova pozvanie'
    },
    confirmations: {
        areYouSureToDeleteTeammate: 'Si si istý, že chceš vymazať člena tímu?',
        areYouSureToResendInvitation:
            'Si si istý, že chceš poslať opäť pozvánku svojmu členovi teamu?',
        yes: 'Áno',
        no: 'Nie'
    },
    successfullyResent: 'Pozvánka bola úspešne odoslaná znova',
    successfullyDeleted: 'Pozvánka bola úspešne vymazaná',
    successfullyDeletedTeammate: 'Spoluhráč bol úspešne odstránený'
})

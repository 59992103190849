import {action, computed, makeObservable, observable, reaction, runInAction, toJS, when} from 'mobx'
import {inject, injectable} from 'inversify'
import {FormInstance, Modal, Typography} from 'antd'
import {ColumnsType} from 'antd/es/table'
import 'reflect-metadata'
import {cancelPayment, getEpayPayments, SearchParameter, sendReceipt} from '@/api'
import {
    CardSchemeType,
    OnlinePaymentStatus,
    SortDirection,
    TABLE_STORAGE_KEYS,
    TableViewType
} from '@/constants'
import {
    OnlinePaymentsDrawerContainerStoreSymbol,
    OnlinePaymentsModalFilterContainerStoreSymbol,
    OnlinePaymentsPaginationStoreSymbol,
    OnlinePaymentsPaymentModalStoreSymbol,
    OnlinePaymentsSearchSelectStoreSymbol,
    OnlinePaymentsTableFilterRangePickerContainerStoreSymbol,
    OnlinePaymentsTableFilterStatusSelectContainerStoreSymbol,
    OnlinePaymentsTabsStatusContainerStoreSymbol,
    TOnlinePaymentsStore
} from '@/pages/OnlinePayments'
import {TRangePickerContainerStore} from '@/components/containers/RangePickerContainer/TRangePickerContainerStore'
import {
    EpayFilterableField,
    EpayPayment,
    EpayPaymentRequest,
    EpaySortableField
} from '@/api/transactions/models'
import {
    camelCaseToSnakeCase,
    getAmountWithCurrentCurrency,
    openErrorNotification,
    openSuccessNotification
} from '@/utils'
import {
    AuthStoreInterface,
    AuthStoreSymbol,
    PeriodType,
    SearchSelectStore,
    formatDate
} from '@/stores'
import {getColumns, removePercentSymbolFromStrings} from './services/ui-utils'
import {TModalContainerStore} from '@/components/containers/ModalContainer/TModalContainerStore'
import {TDrawerContainerStore} from '@/components/containers/DrawerContainer/TDrawerContainerStore'
import {PaginationContainerStoreType, TSearchSelectContainerStore} from '@/components/containers'
import {LoadingState} from '@/types'
import {statusSelectOptions, statusTabs} from '@/stores/transactions/OnlinePaymentsStore/constants'
import {
    filterOperators,
    OnlinePaymentsFilterButtonStore
} from '@/stores/transactions/OnlinePaymentsStore/OnlinePaymentsFilterButtonStore'
import {OnlinePaymentsFilterButtonStoreSymbol} from '@/pages/OnlinePayments/components/OnlinePaymentsFilterButton/OnlinePaymentsFilterButton'
import {OnlinePaymentsTabsStore} from '@/stores/transactions/OnlinePaymentsStore/OnlinePaymentsTabsStore'
import {OnlinePaymentsStatusSelectStore} from '@/stores/transactions/OnlinePaymentsStore/OnlinePaymentsStatusSelectStore'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import React from 'react'
import {SearchParams} from '@/stores/transactions/OnlinePaymentsStore/models'
import {storage} from '@/services/storage'
import moment from 'moment-timezone'
import {
    ProcessNewPaymentStoreSymbol,
    TProcessNewPaymentModalStore
} from '@/pages/OnlinePayments/components/ProcessNewPaymentModal'
import {ProcessNewPaymentRequest} from '@/stores/transactions/ProcessNewPaymentStore'
import {ProfileStoreSymbol} from '@/pages/Profile/Profile'
import {ProfileStoreInterface} from '@/pages/Profile'
import rootTranslations from '@/translations'
import translations from './translations'

@injectable()
export class OnlinePaymentsStore implements TOnlinePaymentsStore {
    private readonly _authStore: AuthStoreInterface
    private readonly _rangePickerStore: TRangePickerContainerStore
    private readonly _statusTabsStore: OnlinePaymentsTabsStore
    private readonly _statusSelectStore: OnlinePaymentsStatusSelectStore
    private readonly _customiseViewModalStore: TModalContainerStore
    private readonly _transactionsPaymentModalStore: TModalContainerStore
    private readonly _drawerStore: TDrawerContainerStore
    private readonly _paginationStore: PaginationContainerStoreType
    private readonly _filterStore: OnlinePaymentsFilterButtonStore
    private readonly _searchSelectStore: TSearchSelectContainerStore
    private readonly _processNewPaymentStore: TProcessNewPaymentModalStore
    private readonly _profileStore: ProfileStoreInterface

    constructor(
        @inject(AuthStoreSymbol) authStore: AuthStoreInterface,
        @inject(OnlinePaymentsTableFilterRangePickerContainerStoreSymbol)
        rangePickerStore: TRangePickerContainerStore,
        @inject(OnlinePaymentsTabsStatusContainerStoreSymbol)
        statusTabsStore: OnlinePaymentsTabsStore,
        @inject(OnlinePaymentsTableFilterStatusSelectContainerStoreSymbol)
        statusSelectStore: OnlinePaymentsStatusSelectStore,
        @inject(OnlinePaymentsModalFilterContainerStoreSymbol)
        customiseViewModalStore: TModalContainerStore,
        @inject(OnlinePaymentsPaymentModalStoreSymbol)
        transactionsPaymentModal: TModalContainerStore,
        @inject(OnlinePaymentsDrawerContainerStoreSymbol) drawerStore: TDrawerContainerStore,
        @inject(OnlinePaymentsPaginationStoreSymbol) paginationStore: PaginationContainerStoreType,
        @inject(OnlinePaymentsFilterButtonStoreSymbol) filterStore: OnlinePaymentsFilterButtonStore,
        @inject(OnlinePaymentsSearchSelectStoreSymbol) searchSelectStore: SearchSelectStore,
        @inject(ProcessNewPaymentStoreSymbol) processNewPaymentStore: TProcessNewPaymentModalStore,
        @inject(ProfileStoreSymbol) profileStore: ProfileStoreInterface
    ) {
        this._authStore = authStore
        this._rangePickerStore = rangePickerStore
        this._statusTabsStore = statusTabsStore
        this._statusSelectStore = statusSelectStore
        this._customiseViewModalStore = customiseViewModalStore
        this._drawerStore = drawerStore
        this._transactionsPaymentModalStore = transactionsPaymentModal
        this._paginationStore = paginationStore
        this._filterStore = filterStore
        this._searchSelectStore = searchSelectStore
        this._processNewPaymentStore = processNewPaymentStore
        this._profileStore = profileStore

        this.initializeStatusStore()

        makeObservable(this, {
            isExportModalOpen: observable,
            loadingState: observable,
            _transactions: observable,
            totalColumns: observable,
            selectedTransaction: observable,
            sortDirection: observable,
            _columns: observable,
            status: observable,
            mobileTableViewType: observable,
            transactions: computed,
            request: computed,
            columns: computed,
            exportsRequest: computed,
            getRangeParametersBySelectedDate: action,
            openDrawer: action.bound,
            loadTransactions: action.bound,
            openPaymentModal: action.bound,
            openProcessNewPaymentModal: action.bound,
            loadProcessNewPayment: action.bound,
            cancelPayment: action.bound,
            openCancelPaymentModal: action.bound,
            setStatus: action.bound,
            loadTransactionsById: action.bound,
            sendReceipt: action.bound,
            openExportsModal: action.bound
        })

        when(
            () => Boolean(this.merchantId),
            () => {
                const hasLocalStorageSettings = Boolean(storage.get(this.storageKey))

                !hasLocalStorageSettings && this.initializeRangePickerStore()
                this.initializePreferences()
            }
        )

        reaction(
            () => ({
                from: this._rangePickerStore.startDate,
                to: this._rangePickerStore.endDate,
                search: this._searchSelectStore.values
            }),
            async () => {
                if (this._authStore.isAuthenticated) {
                    this._paginationStore.setPageNumber(1)
                    await this.loadTransactions()
                }
            }
        )

        reaction(
            () => ({status: this.status}),
            async () => {
                if (this._authStore.isAuthenticated) {
                    this._paginationStore.setPageNumber(1)
                    await this.loadTransactions()
                }
            }
        )

        reaction(
            () => ({
                paging: this._paginationStore.paging,
                searchParameters: this._filterStore.searchParameters
            }),
            async () => {
                if (this._authStore.isAuthenticated) {
                    await this.loadTransactions()
                }
            }
        )

        reaction(
            () => this._authStore.isAuthenticated,
            (isAuthenticated) => {
                if (isAuthenticated) {
                    this.initializePreferences()
                }
            }
        )
    }

    _transactions: EpayPayment[] = null
    _columns = getColumns(this as TOnlinePaymentsStore)
    sortDirection: SortDirection = SortDirection.DESC
    sortField = 'createdDate'
    status = OnlinePaymentStatus.all
    mobileTableViewType = TableViewType.EXPANDED
    isExportModalOpen = false

    public selectedTransaction: EpayPayment = {} as EpayPayment
    public totalColumns = getColumns(this as TOnlinePaymentsStore).map((column) => ({
        title: column.title,
        open: true,
        field: column.key
    }))

    getRangeParameters(): Array<SearchParameter<EpayFilterableField>> {
        return [
            {
                name: 'created_date',
                method: 'between',
                searchParameter: [
                    formatDate(this._rangePickerStore.startDate),
                    formatDate(this._rangePickerStore.endDate)
                ]
            }
        ]
    }

    getRangeParametersBySelectedDate(): Array<SearchParameter<EpayFilterableField>> {
        let startDate

        if (this._rangePickerStore.startDate > moment().subtract(12, 'months').startOf('day')) {
            startDate = moment().subtract(12, 'months').startOf('day')
        } else {
            startDate = this._rangePickerStore.startDate
        }

        return [
            {
                name: 'created_date',
                method: 'between',
                searchParameter: [formatDate(startDate), formatDate(moment().endOf('day'))]
            }
        ]
    }

    get merchantId() {
        return this._profileStore?.merchant?.merchantId
    }

    get columns(): ColumnsType<EpayPayment> {
        return this._columns
    }

    get requestSearchParameters() {
        const result = this.getRangeParameters()

        let _status = this.status

        const refundDateFields = result.filter((f) => (f.name as string) === 'refund_date')
        if (refundDateFields.length > 0) {
            _status = OnlinePaymentStatus.refund
            refundDateFields.forEach((f) => (f.name = 'payout_date'))
        }

        const refundAmountFields = result.filter((f) => (f.name as string) === 'refund_amount')
        if (refundAmountFields.length > 0) {
            _status = OnlinePaymentStatus.refund
            refundAmountFields.forEach((f) => (f.name = 'payout_amount'))
        }

        if (_status) {
            result.push({
                name: 'status_id',
                method: '=',
                searchParameter: [_status]
            })
        }

        return result
    }

    get request() {
        return {
            searchParameters: this.requestSearchParameters,
            orderParameters: [
                {
                    field: camelCaseToSnakeCase(this.sortField) as EpaySortableField,
                    typeOrder: this.sortDirection
                },
                {
                    field: 'id',
                    typeOrder: this.sortDirection
                }
            ]
        } as EpayPaymentRequest
    }

    get exportsRequest() {
        const {formValues} = this._filterStore

        return {
            from: this._rangePickerStore.startDate,
            to: this._rangePickerStore.endDate,
            stores: this._filterStore.storeIDs,
            statuses: this.status && this.status.length ? [this.status] : undefined,
            paymentMethods: formValues['payment_method']
                ? [this._filterStore.formValues['payment_method']]
                : undefined,
            currency:
                formValues['currency_id'] && formValues['currency_id'].length
                    ? formValues['currency_id']
                    : undefined,
            merchantReference:
                formValues['invoice_id'] && formValues['invoice_id'].length
                    ? formValues['invoice_id']
                    : undefined,
            description:
                formValues['description'] && formValues['description'].length
                    ? formValues['description']
                    : undefined,
            payerName:
                formValues['payer_name'] && formValues['payer_name'].length
                    ? formValues['payer_name']
                    : undefined,
            payerEmail:
                formValues['payer_email'] && formValues['payer_email'].length
                    ? formValues['payer_email']
                    : undefined,
            amountFrom:
                formValues.amount && formValues.amount.from && formValues.amount.from.length
                    ? parseFloat(formValues.amount.from)
                    : undefined,
            amountTo:
                formValues.amount && formValues.amount.to && formValues.amount.to.length
                    ? parseFloat(formValues.amount.to)
                    : undefined,
            cardMask: formValues['card_mask']
                ? this.getCardMask(formValues['card_mask'].from, formValues['card_mask'].to)
                : undefined,
            cardSchemes:
                formValues['card_type_id']?.length > 0 ? formValues['card_type_id'] : undefined,
            accountId:
                formValues['account_id'] && formValues['account_id'].length
                    ? formValues['account_id']
                    : undefined,
            reference:
                formValues.reference && formValues.reference.length
                    ? formValues.reference
                    : undefined,
            postLinkStatus:
                formValues['post_link_status'] === undefined ||
                formValues['post_link_status'] === ''
                    ? undefined
                    : formValues['post_link_status'],
            donationAmountFrom:
                formValues['donation_amount'] &&
                formValues['donation_amount'].from &&
                formValues['donation_amount'].from.length
                    ? parseFloat(formValues['donation_amount'].from)
                    : undefined,
            donationAmountTo:
                formValues['donation_amount'] &&
                formValues['donation_amount'].to &&
                formValues['donation_amount'].to.length
                    ? parseFloat(formValues['donation_amount'].to)
                    : undefined
        }
    }

    protected get profileEmail() {
        return storage.get('email')
    }

    protected get storageKey() {
        return `${TABLE_STORAGE_KEYS.onlinePayments}-${this.profileEmail}-${this.merchantId}`
    }

    protected get preferences() {
        return undefined
    }

    protected set preferences(value) {
        // override
    }

    private getCardMask(firstPart = '', lastPart = '') {
        if (firstPart.length || lastPart.length) return `${firstPart}%${lastPart}`
        else return undefined
    }

    private getPlainPreferences = () =>
        toJS(
            observable({
                pageSize: this._paginationStore.paging.size,
                pageNumber: this._paginationStore.paging.page,
                sortField: this.sortField,
                sortDirection: this.sortDirection,
                startDate: this._rangePickerStore.startDate,
                endDate: this._rangePickerStore.endDate,
                status: this._statusSelectStore.value,
                formValues: removePercentSymbolFromStrings(this._filterStore.formValues),
                ...this.preferences
            })
        )

    private initializePreferences = () => {
        const value = storage.get(this.storageKey) && JSON.parse(storage.get(this.storageKey))

        if (value) {
            const {
                sortField,
                sortDirection,
                period: p,
                startDate,
                endDate,
                formValues,
                status,
                ...rest
            } = value

            const period = p as PeriodType

            if (formValues) {
                const formattedFormValues = removePercentSymbolFromStrings(formValues)

                this._filterStore.onFormValuesChange(
                    formattedFormValues,
                    this._filterStore.onFinish
                )
            }

            if (status) {
                this._statusSelectStore.setValue(status)
            }

            if (period && period !== PeriodType.Custom) {
                this._rangePickerStore.setPeriod(period)
            } else if (startDate && endDate) {
                this._rangePickerStore.setDates([moment(startDate), moment(endDate)])
            }

            if (sortField && sortDirection) {
                this.sortField = sortField
                this.sortDirection = sortDirection
            }

            this.preferences = rest
        }

        reaction(this.getPlainPreferences, (preferences) => {
            if (this.profileEmail) {
                const period = this._rangePickerStore.period
                storage.set(this.storageKey, JSON.stringify({...preferences, period}))
            }
        })
    }

    private initializeRangePickerStore = () => {
        this._rangePickerStore.setPeriod(PeriodType.Day)
    }

    private initializeStatusStore = () => {
        this._statusTabsStore.setTabs(statusTabs)
        this._statusTabsStore.parentStore = this
        this._statusSelectStore.setOptions(statusSelectOptions)
        this._statusSelectStore.parentStore = this
    }

    public setMobileTableViewType = (tableViewType: TableViewType) =>
        (this.mobileTableViewType = tableViewType)

    public get transactions(): EpayPayment[] {
        if (!this._transactions && this.merchantId) {
            this.loadTransactions()

            return []
        }

        return this._transactions
    }

    public updateSelectedTransactionInList() {
        if (!this.transactions?.length || !this.selectedTransaction) {
            return
        }

        const item = this.transactions.find((item) => item.id === this.selectedTransaction.id)

        if (item) {
            this.selectedTransaction = {
                ...this.selectedTransaction,
                ...item
            }
        }
    }

    public async loadTransactions(): Promise<void> {
        if (this.loadingState === LoadingState.LOADING) {
            return
        }

        runInAction(() => {
            this.loadingState = LoadingState.LOADING
        })

        const orderEndsWithSearchValue = this._searchSelectStore.values?.find(
            (item) => item.value === SearchParams.ORDER
        )?.query
        let searchByOrderEndsWith = []
        if (orderEndsWithSearchValue) {
            searchByOrderEndsWith = [
                {
                    method: filterOperators.endsWith,
                    name: 'invoice_id',
                    searchParameter: [`%${orderEndsWithSearchValue}%`]
                }
            ]
        }

        const descriptionLikeSearchValue = this._searchSelectStore.values?.find(
            (item) => item.value === SearchParams.DESCRIPTION
        )?.query
        let searchByDescriptionLike = []
        if (descriptionLikeSearchValue) {
            searchByDescriptionLike = [
                {
                    method: filterOperators.endsWith,
                    name: 'description',
                    searchParameter: [`%${descriptionLikeSearchValue}%`]
                }
            ]
        }

        const transactionIdSearchValue = this._searchSelectStore.values?.find(
            (item) => item.value === SearchParams.TRANSACTION_ID
        )?.query
        let searchByTransactionId = []
        if (transactionIdSearchValue) {
            searchByTransactionId = [
                {
                    method: filterOperators.equals,
                    name: 'id',
                    searchParameter: [transactionIdSearchValue]
                }
            ]
        }

        const payerNameStartsWithSearchValue = this._searchSelectStore.values?.find(
            (item) => item.value === SearchParams.CUSTOMER_NAME
        )?.query
        let searchByPayerNameStartsWith = []
        if (payerNameStartsWithSearchValue) {
            searchByPayerNameStartsWith = [
                {
                    method: filterOperators.startsWith,
                    name: 'payer_name',
                    searchParameter: [`%${payerNameStartsWithSearchValue}%`]
                }
            ]
        }

        const emailEndsWithSearchValue = this._searchSelectStore.values?.find(
            (item) => item.value === SearchParams.EMAIL_STARTS_WITH
        )?.query
        let searchByEmailEndsWith = []
        if (emailEndsWithSearchValue) {
            searchByEmailEndsWith = [
                {
                    method: filterOperators.startsWith,
                    name: 'payer_email',
                    searchParameter: [`${emailEndsWithSearchValue}%`]
                }
            ]
        }

        const cardStartsWithSearchValue = this._searchSelectStore.values?.find(
            (item) => item.value === SearchParams.CARD_STARTS_WITH
        )?.query
        let searchByCardStartsWith = []
        if (cardStartsWithSearchValue) {
            searchByCardStartsWith = [
                {
                    method: filterOperators.startsWith,
                    name: 'card_mask',
                    searchParameter: [`${cardStartsWithSearchValue}%`]
                }
            ]
        }

        const cardEndsWithSearchValue = this._searchSelectStore.values?.find(
            (item) => item.value === SearchParams.CARD_ENDS_WITH
        )?.query
        let searchByCardEndsWith = []
        if (cardEndsWithSearchValue) {
            searchByCardEndsWith = [
                {
                    method: filterOperators.endsWith,
                    name: 'card_mask',
                    searchParameter: [`%${cardEndsWithSearchValue}`]
                }
            ]
        }

        const payerAccountIdSearchValue = this._searchSelectStore.values?.find(
            (item) => item.value === SearchParams.CUSTOMER_ACCOUNT_ID
        )?.query
        let searchByPayerAccountId = []
        if (payerAccountIdSearchValue) {
            searchByPayerAccountId = [
                {
                    method: filterOperators.startsWith,
                    name: 'account_id',
                    searchParameter: [`%${payerAccountIdSearchValue}%`]
                }
            ]
        }

        const params = {
            paging: this._paginationStore.paging,
            ...this.request,
            searchParameters: [
                ...this.request.searchParameters,
                ...this._filterStore.searchParameters,
                ...searchByTransactionId,
                ...searchByDescriptionLike,
                ...searchByOrderEndsWith,
                ...searchByEmailEndsWith,
                ...searchByPayerNameStartsWith,
                ...searchByCardStartsWith,
                ...searchByCardEndsWith,
                ...searchByPayerAccountId
            ]
        }

        const showError = (message: string) => {
            runInAction(() => {
                this.loadingState = LoadingState.FAILED
                this._transactions = []
                this._paginationStore.setTotal(0)
            })

            if (message) {
                openErrorNotification(message)
            }
        }

        try {
            const {result, error} = await getEpayPayments(params)

            if (!error && result) {
                runInAction(() => {
                    this._transactions =
                        result.records?.map((record) => ({
                            ...record,
                            cardType: record.cardType.toLowerCase() as CardSchemeType
                        })) || []
                    this._paginationStore.setTotal(result.totalCount)
                    this.loadingState = LoadingState.DONE
                })
            } else {
                showError(error?.message)
            }
        } catch (error) {
            showError(rootTranslations().errors.general)
        }
    }

    public async loadTransactionsById(
        id: string,
        filterBy: string,
        loadByCurrentDate: boolean
    ): Promise<void> {
        if (this.loadingState === LoadingState.LOADING) {
            return
        }

        runInAction(() => {
            this.loadingState = LoadingState.LOADING
        })

        let searchById = []
        if (id) {
            searchById = [
                {
                    method: filterBy,
                    name: 'id',
                    searchParameter: [`${id}`]
                }
            ]
        }

        const params = {
            paging: {
                page: 0,
                size: 20
            },
            ...this.request,
            searchParameters: [
                ...(loadByCurrentDate
                    ? this.getRangeParametersBySelectedDate()
                    : this.getRangeParameters()),
                ...searchById
            ]
        }

        try {
            const {result, error} = await getEpayPayments(params)

            runInAction(() => {
                if (error) {
                    this.loadingState = LoadingState.FAILED
                    return openErrorNotification(error?.message)
                }

                if (result) {
                    const fetchedTransaction = result.records && result.records[0]
                    this._transactions = this._transactions?.map((transaction) => {
                        if (transaction.id === fetchedTransaction?.id) {
                            return fetchedTransaction
                        }
                        return transaction
                    })

                    this.selectedTransaction = fetchedTransaction
                }

                this.loadingState = LoadingState.DONE
            })
        } catch (error) {
            runInAction(() => {
                this.loadingState = LoadingState.FAILED
                return openErrorNotification(rootTranslations().errors.general)
            })
        }
    }

    public setStatus(status) {
        this.status = status
    }

    public openDrawer(selectedTransaction: EpayPayment) {
        this.selectedTransaction = selectedTransaction
        this._drawerStore.setOpen(true)
    }

    public openPaymentModal(selectedTransaction?: EpayPayment) {
        if (selectedTransaction) {
            this.selectedTransaction = selectedTransaction
        }

        this._transactionsPaymentModalStore.setOpen(true)
    }

    public openProcessNewPaymentModal(selectedTransaction?: EpayPayment) {
        if (selectedTransaction) {
            this.selectedTransaction = selectedTransaction
        }

        this._processNewPaymentStore.setOpen(true)
    }

    public async loadProcessNewPayment(values: ProcessNewPaymentRequest, form: FormInstance) {
        this._processNewPaymentStore.loadProcessNewPayment(values).then((res) => {
            if (!res.result) {
                openErrorNotification(rootTranslations().errors.general)
                return
            }

            if (res.error) {
                openErrorNotification(
                    <>
                        <Typography.Title level={4}>{translations().error}</Typography.Title>
                        {res.error.message}
                    </>
                )
                return
            } else {
                this.loadTransactions()
                this._processNewPaymentStore.setOpen(false)
                this._drawerStore.setOpen(false)
                form.resetFields()
                if (!res.result?.success) {
                    openErrorNotification(res.result?.message)
                } else {
                    openSuccessNotification(
                        rootTranslations().success.newPaymentProcessedSuccessfully
                    )
                }
            }
        })
    }

    public async sendReceipt(email: string) {
        return await sendReceipt(this.selectedTransaction?.id, email)
    }

    public openCancelPaymentModal = async (payment: EpayPayment) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: translations().cancelPaymentInTheAmount(
                getAmountWithCurrentCurrency(payment.amount, payment.currency)
            ),
            okText: translations().ok,
            cancelText: translations().cancel,
            onOk: () => this.cancelPayment(payment.id)
        })
    }

    public cancelPayment = async (paymentId: string) => {
        try {
            const {error} = await cancelPayment(paymentId)

            if (error) {
                openErrorNotification(error.message)
            } else {
                this.loadTransactions()
                this._drawerStore.setOpen(false)
                openSuccessNotification(translations().successfullyCancelled)
            }
        } catch (error) {
            openErrorNotification(rootTranslations().errors.general)
        }
    }

    public openExportsModal = (value: boolean) => {
        this.isExportModalOpen = value
    }

    public async onAfterPaymentAction(isSuccessfull: boolean) {
        await this.loadTransactions()
        if (isSuccessfull) {
            this.updateSelectedTransactionInList()
        }
    }

    loadingState: LoadingState = LoadingState.IDLE
}

import React from 'react'

import {useAuthStore} from '@/stores'
import {BackButton} from '@/components'

import translations from './translations'
import styles from './styles.scss'

export const BackToLoginLink = () => {
    const {backToLogin} = useAuthStore()

    return (
        <BackButton
            className={styles.backToLogin}
            onClick={backToLogin}
            title={translations().back}
        />
    )
}
